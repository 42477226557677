<template>
  <footer class="footer tw-px-5 lg:tw-px-40 tw-pt-10 ">
    <div
      class="tw-flex tw-justify-start
        md:tw-items-start md:tw-flex-row
        tw-flex-col tw-flex-wrap">
      <v-col sm="12" md="2" class="first-box">
        <div class="logo-div">
          <img
            :src="companyData.companyLogo"
            alt=""
            @click="routeToHome"
            class="company-logo"
          />
            <p class="footer-header text-capitalize">{{companyData.companyName}}</p>
        </div>

      </v-col>
      <v-col sm="6" md="3" class="second-box">
        <p class="footer-header">Contact Us</p>
          <div class="tw-flex tw-items-start tw-mt-5 address-box">
              <img src="@/assets/location.svg" alt="" />
              <div v-if="companyData.address">
                  <label class="footer-desc lg:tw-pr-4 text-capitalize">
                      {{companyData.address.houseNumber}} {{companyData.address.streetName}}, {{companyData.address.city}} {{companyData.address.state}} {{companyData.address.country}}.
                  </label>
              </div>
          </div>
      </v-col>
      <v-col sm="6" md="2" class="tw-flex tw-flex-col">
        <p class="footer-header">Quick Links</p>
        <router-link class="footer-desc" to="/login">Company Login</router-link>
          <div class="footer-desc tw-cursor-pointer tw-pt-5" @click="scrollTo('topDeals')">Top Deals</div>
          <div class="footer-desc tw-pt-5 tw-cursor-pointer" @click="scrollTo('topCities')">Top Cities</div>
          <div class="footer-desc tw-pt-5 tw-cursor-pointer" @click="scrollTo('topTerminals')">Top Terminals</div>
          <div class="footer-desc tw-pt-5 tw-cursor-pointer" @click="routeToSignUp">Become An Agent</div>
      </v-col>

    </div>
    <v-divider :style="{backgroundColor: companyData.companyColor}"/>
    <div class="right-reserved">
      <div class="right-reserved-inner">
        All rights Reserved
        <img
          src="@/assets/copy.svg"
          class="tw-mx-2"
          style="width: 22px; height: 22px"
          alt=""
        />
        {{ year }}
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  components: {

  },
  name: "Footer",
  data() {
    return {
      year: "",
      subscriberEmail: "",
      loading: false,
      logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1631672100/assets/frontend/logo_kpzfg4.png",
    };
  },
  computed:{
      ...mapGetters("companyProfile", ["companyData"])
  },
  methods: {
    routeToHome() {
      if (this.$route.name !== "NewBooking")
        this.$router.push({ name: "NewBooking" });
    },
      routeToSignUp(){
          window.open(process.env.VUE_APP_MAIN_FRONTEND_URL+"/mobile-agent/register", "_blank")
      },
    getYear() {
      let date = new Date();
      this.year = date.getFullYear();
    },
      scrollTo(id) {
          const element = document.getElementById(id);
          const top = element.offsetTop -40;
          window.scrollTo({
              top,
              behavior: 'smooth',
          });
          return ""
      },

  },
  mounted() {
    this.getYear();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  min-height: 30vh;
  @media screen and (max-width: 1264px) {
    padding: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0.8rem;
  }
  @media screen and (max-width: 360px) {
    padding: 0.3rem;
  }
  position: relative;
  background: #f6f8fc;

  .first-box {
    .logo-div {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

  }
  .address-box {
    img {
      margin-top: 0;
      margin-right: 8px;
    }
  }
  .address-text {
    height: 72px;
    width: 269px;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(38, 50, 56, 1);

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .box-text {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin: 18px 0;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
  }

  .right-reserved {
    margin-top: 2rem;
    padding-bottom: 2rem;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .right-reserved-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 5rem !important;
    @media screen and (max-width: 768px) {
      padding-right: 0 !important;
    }
  }

  //.third-box {
  //    padding: 10px;
  //    height: 42px;
  //    font-size: 12px;
  //    border-radius: 5px;
  //    color: rgba(38, 50, 56, 0.761) !important;
  //    @media (max-width: 960px) {
  //      width: 180px;
  //    }
  //}
}
.dev-class {
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
}

.footer-header{
  font-family: 'Raleway',sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #263238;
}
.footer-desc{
  font-family: 'Raleway',sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;

}
.company-logo{
  height: 50px;
  width: 100px;
  object-fit: contain;
}
</style>

/* eslint-disable no-dupe-keys */
<template>
    <v-app-bar height="auto" elevation="0" fixed :color="bg === 'white' ? 'white' : 'transparent'"
               style="background: inherit !important" :class="[icon !== 'x' ? 'appBar-none' : 'appBar']"
               :style="{ background: bg }" class="ipad tw-z-40">
        <page-layout>
            <template #Content>
                <div class="tw-flex tw-w-full">
                    <div class="tw-hidden lg:tw-flex tw-w-full tw-items-center tw-py-5 tw-justify-between">
                        <!-- web starts here -->
                        <!-- first -->
                        <div class="tw-flex tw-flex-col">
                        <img :src="companyData.companyLogo ? companyData.companyLogo : ''"  alt="logo" class="tw-cursor-pointer company-logo" @click="routeToHome" />
                        <label class="company-name">{{companyData.companyName}}</label>
                        </div>
                        <!-- second -->
                        <div class="tw-flex tw-flex-row tw-items-end" style="height: 40px">
                  <v-tabs v-model="currentTab" slider-color="#004aad" :color="companyData.companyColor" :hide-slider="
                    $route.name === 'NewBooking' ||
                    $route.name === 'OneWayTrips' ||
                    $route.name === 'OneWaySelectSeats' ||
                    $route.name === 'OneWayInputDetails' ||
                    $route.name === 'OneWayCheckout'">
                    <v-tab to="#topDeals" @click="scrollTo('topDeals')">Top Deals</v-tab>
                    <v-tab to="#topCities" @click="scrollTo('topCities')">Top Cities</v-tab>
                    <v-tab to="#topTerminals" @click="scrollTo('topTerminals')">Top Terminals</v-tab>

                  </v-tabs>
                  <v-btn text class="agent-btn" :style="{backgroundColor: companyData.companyColor}" @click="routeToSignUp" >Become An Agent</v-btn>
                </div>

                    </div>

                    <!-- web ends here -->
                    <div class="
              lg:tw-hidden
              tw-w-full tw-flex tw-flex-col tw-h-auto tw-bg-white md:tw-bg-transparent
            ">
             <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-px-0 small-screen">
               <img class="tw-cursor-pointer company-logo" :src="companyData.companyLogo ? companyData.companyLogo:logo"  alt="" @click="routeToHome" />
                 <vue-feather style="color: black" :type="icon" @click="toggelIcon" class="tw-cursor-pointer" />
             </div>
                <v-expand-transition>
                    <div class="tw-flex tw-w-full tw-flex-col tw-py-5 tw-px-2 tw-bg-white" v-if="icon === 'x'" :class="[ icon === 'x' ? 'anim' : '']">
                        <h6 class="v-tab"  @click="scrollTo('topDeals')">Top Deals</h6>
                        <h6 class="v-tab" @click="scrollTo('topCities')">Top Cities</h6>
                        <h6 class="v-tab" @click="scrollTo('topTerminals')">Top Terminals</h6>

                        <v-btn text class="agent-btn" :style="{backgroundColor: companyData.companyColor}" @click="routeToSignUp" >Become An Agent</v-btn>
                    </div>
                </v-expand-transition>

              </div>
                </div>
            </template>
        </page-layout>
    </v-app-bar>
</template>

<script>
import PageLayout from "@/components/reuseables/PageLayout";
import {mapGetters} from "vuex";

export default {
    name: "LandingPageNavBar",
    components: {

        PageLayout,
    },
    data() {
        return {
            logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png",
            icon: "menu",
            bg: "",
            currentTab: "NewBooking",
        };
    },
    watch: {
        "$route.name": {
            handler: function (val) {
                if (val === "About") {
                    this.currentTab = "About";
                } else if (val === "Blog") {
                    this.currentTab = "Blog";
                } else if (val === "Contact") {
                    this.currentTab = "Contact";
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters("companyProfile", ["companyData"])
    },
    methods: {
        toggelIcon() {
            console.log(this.icon, "the icon............")
            if (this.icon === "menu") {
                this.icon = "x";
                console.log(this.icon, "the icon............")
            } else {
                this.icon = "menu";
                console.log(this.icon, "the icon............")
            }
        },
        closeToggle() {
            this.icon = "menu";
        },
        routeToSignUp(){
            window.open(process.env.VUE_APP_MAIN_FRONTEND_URL+"/mobile-agent/register", "_blank")
        },
        routeToHome() {
           let user = JSON.parse(sessionStorage.getItem('userData'))
            if (this.$route.name !== "NewBooking")
                this.$router.push({
                    name: "NewBooking"
                });
            else if (this.$route.name === "NewBooking" && user ){
                if (user.user_role === 'transport_company'){
                    this.$router.push({name : 'MainDashboard'})
                }
                else if (user.user_role === 'transportCompanyManager' || user.user_role === 'transportCompanyBookingAdmin'){
                    this.$router.push({name : 'CompanyStaffDashboard'})
                }
            }
        },

        scrollTo(id) {
            this.toggelIcon()
            const element = document.getElementById(id);
            const top = element.offsetTop - 40;
            window.scrollTo({
                top,
                behavior: 'smooth',
            });
        },
        changeColor() {
            if (
                document.body.scrollTop > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                this.bg = "white";
            } else {
                this.bg = "transparent";
            }
        },
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
};
</script>

<style scoped lang="scss">
.v-tab {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 24px;
  color: var(--padiGrey);
  text-transform: none;
  letter-spacing: 0 !important;

  @media (max-width: 1024px) {
    max-width: 100% !important;
    justify-content: left;
    padding: 5px;
  }
}

::v-deep .theme--light.v-tabs>.v-tabs-bar {
  background-color: transparent !important;
}

.ipad {
  z-index: 10;
  padding-right: 10rem !important;
  padding-left: 10rem !important;

  @media screen and (min-width: 768px) and (max-width: 1264px) {
    padding: 0.5rem 1rem !important;
  }

  @media screen and (max-width: 768px) {
    padding: 10px !important;
  }

}

.v-toolbar__content {
  padding: 4px 0 !important;
}
::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0!important;
}


.small-screen {
  @media screen and (max-width: 370px) {
    padding: 0 0.5rem;
  }
}

.appBar-title {
  font-family: "Raleway", serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none !important;
  text-align: left;
  color: #263238;
}

.item-css {
  width: 100%;
  height: 76px;
  cursor: pointer;
  font-family: Raleway, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #154b94;

  @media (max-width: 1024px) {
    padding: 10px 20px;
  }
}

.anim {
  transition: 5s;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
  opacity: 1;
  animation: infinite;
}

.item-css:hover {
  height: 76px;
  background: rgba(124, 176, 246, 0.05);
  width: 100%;
  //cursor: pointer;
}

.sign-in {
  font-family: Raleway, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}

.get-started {
  border: 1px solid #004aad;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Raleway, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}

.appBar-title.signup {
  background: #004aac;
  border-radius: 6px;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 3rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 46px!important;
}
::v-deep .v-btn__content {
  font-family: Raleway, sans-serif !important;
}

.company-logo{
  height: 50px;
  width: 100px;
  object-fit: contain;
}
.company-name{
  font-family: 'Raleway',sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #263238;
}

.product-container{
  width: 424px;
  min-height: 331px;
  background: #FDFFFC;
  box-shadow: 0 4px 60px rgba(38, 50, 56, 0.1);
  border-radius: 10px;

}
.product-item:hover{
  background-color: #FDFFDD;

}
.product-container-header{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #263238;
}
.product-container-desc{
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: #263238;
  cursor: pointer;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 2s ease;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 2s ease;
}

.agent-btn{
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: max-content;
  text-transform: capitalize !important;
  padding: 0 1rem;
}
</style>